


































import Vuetable from "vuetable-2";
import { namespace } from "vuex-class";
import { TicketTemplate } from "@/models/ticket-template.model";
import Component from "vue-class-component";
import Vue from "vue";
import { PaginatedResult } from "@/shared/types/paginated-result.class";

const ticketTemplatesNamespace = namespace("TicketTemplates");

@Component({ components: { Vuetable } })
export default class TicketTemplates extends Vue {
  isLoading = false;
  selectedTicketTemplate = null;
  showModal = false;

  @ticketTemplatesNamespace.Getter("all")
  ticketTemplates!: PaginatedResult<TicketTemplate>;

  @ticketTemplatesNamespace.Action("fetchAll")
  fetchTicketTemplates!: () => Promise<TicketTemplate[]>;

  @ticketTemplatesNamespace.Action("delete")
  deleteTicketTemplate!: (id: string) => Promise<TicketTemplate>;

  async created() {
    this.selectedTicketTemplate = null;
    await this.fetchTicketTemplates();
  }

  addTicketTemplate() {
    this.$router.push({ name: "ticket-template-create" });
  }

  editTicketTemplate(index) {
    this.$router.push({
      name: "ticket-template-detail",
      params: { id: this.ticketTemplates.data[index].id },
    });
  }

  async deleteMail(ticketTemplate: TicketTemplate, index: number) {
    this.isLoading = true;
    const isConfirmed = await this.$bvModal.msgBoxConfirm(
      `Are you sure you want to remove ticket template "${ticketTemplate.name}"?`,
      {
        title: "Confirm delete",
        okVariant: "danger",
        centered: true,
      }
    );
    if (isConfirmed) {
      await this.deleteTicketTemplate(ticketTemplate.id);
      await this.fetchTicketTemplates();
    }
    this.isLoading = false;
  }
}
